import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG, AppConfig, setConfig } from './app/app.config'; // import only app.config 



fetch('./config.json').then((response: Response) => {
	if (response.ok) {
		return response.json();
	} else {
		return Promise.reject(new Error('Config file loading error...'));
	}
}).then(async (config: AppConfig) => {
	setConfig(config);

	if (config.envName !== 'default') {
		enableProdMode();
	}

	// Disable console log in production environment
	if (config.envName == 'production' && window) {
		window.console.table = window.console.log = window.console.error = window.console.warn = () => { };
	}

	const appModuleContainer = await import('./app/app.module');
	platformBrowserDynamic([
		{ provide: APP_CONFIG, useValue: config }
	]).bootstrapModule(appModuleContainer.AppModule)
		.catch(err => console.error(err));

});

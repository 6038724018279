import { InjectionToken } from '@angular/core';
import { AuthCoreConfig } from '@ortho-next/auth-core';


let config: AppConfig = null;

export const APP_CONFIG = new InjectionToken<AppConfig>('AppConfig');

export const setConfig = (conf: AppConfig) => config = conf;
export const getConfig = () => config;

export interface AppConfig {
	envName: string;
	buildVer: string;
	insightKey: string;
	domainPatientAPI: string;
	domainUserAPI: string;
	domainImageAPI: string;
	domainRayAPI: string;
	domainTemplatesAPI: string;
	sseAPI: string;
	cdnUrl: string;
	b2cTenantName: string;
	b2cTenantId: string;
	b2cSignInPolicyId: string;
	b2cResetPasswordPolicyId: string;
	b2cChangePasswordPolicyId: string;
	b2cClientId: string;
	b2cApplicationScope: string;
	appSSOutUriApi: string;
	idleTimeToEndSession: number;
	patplanSite: string;
	planDetailsSite: string;
	nextraySite: string;
	legalTermsSite: string;
	aboutSite: string;
	guideSite: {
		baseUrl: string;
		list: string;
	};
	helpSite: {
		baseUrl: string;
		fileNames: string;
	};
	eifuSite: string;
	fitboneModelVersion: string;
	cookieName: string;
}

/**
* Authentication configuration for application
*/
export const AUTH_CONFIG = (): AuthCoreConfig => {
	return {
		allowedUrls: [
			config.domainUserAPI,
			config.domainPatientAPI,
			config.domainImageAPI,
			config.domainRayAPI,
			config.domainTemplatesAPI
		],
		appSSOutUriApi: config.appSSOutUriApi,
		applicationScope: config.b2cApplicationScope,
		clientId: config.b2cClientId,
		idleTimeToEndSession: config.idleTimeToEndSession,
		tenantName: config.b2cTenantName,
		resetPasswordPolicyId: config.b2cResetPasswordPolicyId,
		signInPolicyId: config.b2cSignInPolicyId,
		showDebugInformation: config.envName !== 'production'
	};
}
